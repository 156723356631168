<template>
  <div class="d-flex-column align-center main">
    <div class="mw-400 input-underline">
      <el-select placeholder="Select member" v-model="selectVal" class="full-width">
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </div>

    <!--  TABLE START  -->
    <div class="table-wrapper d-flex">
      <div class="el-table">
        <table>
          <colgroup>
            <col name="col_1" />
            <col name="col_2" />
            <col name="col_3" />
          </colgroup>

          <thead class="bg-transparent">
            <tr>
              <th colspan="1" rowspan="1" class="col_1 is-leaf">
                <div class="cell">Accreditation Id</div>
              </th>
              <th colspan="1" rowspan="1" class="col_2 is-leaf">
                <div class="cell">Name</div>
              </th>
              <th colspan="1" rowspan="1" class="col_2 is-leaf">
                <div class="cell">Type</div>
              </th>
              <th colspan="1" rowspan="1" class="col_3 is-leaf">
                <div class="cell">Status</div>
              </th>
              <th colspan="1" rowspan="1" class="col_3 is-leaf">
                <div class="cell">Expiry</div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, index) in tableData" :key="index" class="el-table__row">
              <td v-for="(col, i) in row" :key="i">
                <div class="cell">{{ col }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <p>Looking to enrol in an NRL education course?</p>
    <el-button @click="redirect" type="primary" class="full-width mw-400">
      Register
    </el-button>
    <p style="text-align:center">
      For all Touch Football courses and training details, <br />please go to
      <a href="https://touchfootball.etrainu.com">touchfootball.etrainu.com</a>.
    </p>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapMutations } from "vuex";

import msg from "@/utils/constants/msg";
import endpoints, { endpointParams } from "@/utils/constants/api";

export default {
  name: "accreditations",
  mounted() {
    this.LOADING(true);
    this.$http
      .get(endpoints.getMembers)
      .then(membersResponse => {
        this.members = membersResponse.data.data;
        const options = membersResponse.data.data.map(({ profile, _id }) => ({
          label: `${profile.firstName} ${profile.lastName}`,
          value: _id
        }));
        this.$set(this, "options", options);
        this.LOADING(false);
      })
      .catch(() => {
        this.notify({
          msg: msg.error.apiError,
          route: this.$route.name,
          type: "warning",
          window
        });
        this.LOADING(false);
      });
  },
  watch: {
    selectVal() {
      this.getAccreditations(this.selectVal);
    }
  },
  methods: {
    ...mapActions("views", ["notify"]),
    ...mapMutations("root", ["LOADING"]),
    async getAccreditations(id) {
      let memberProfile = this.members.find(x => x._id === id).profile,
        imcId = memberProfile.meta ? memberProfile.meta.imcId : null;
      if (!imcId) {
        this.$set(this, "tableData", []);
        return;
      }
      this.LOADING(true);
      try {
        const res = await this.$http.get(endpointParams.profile.accreditations(imcId));
        const { data } = res.data;
        const result = data.map(line => ({
          skillId: line.skillId,
          skillName: line.skillName,
          skillDescription: line.skillDescription,
          achievedValue: this.achievedValueFormatter(line.achievedValue),
          expiry: line.expiry
        }));
        this.$set(this, "tableData", result);
      } catch (error) {
        this.notify({
          msg:
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : msg.error.apiError,
          route: this.$route.name,
          type: "warning",
          window
        });
      }
      this.LOADING(false);
    },
    achievedValueFormatter(achievedValue) {
      if (achievedValue !== undefined) {
        switch (true) {
          case _.inRange(achievedValue, 0, 1):
            return "Expired";
          case _.inRange(achievedValue, 50, 51):
            return "In Training";
          case _.inRange(achievedValue, 1, 50):
          case _.inRange(achievedValue, 51, 101):
            return "Complete";
          default:
            return "N/A";
        }
      }
      return "N/A";
    },
    redirect() {
      window.location.href = "https://learn.playrugbyleague.com/ilp/pages/login.jsf ";
    }
  },

  data() {
    return {
      options: [],
      selectVal: "",
      tableData: [],
      members: []
    };
  }
};
</script>

<style lang="scss" scoped>
.el-table {
  overflow-x: auto;
  background-color: transparent;

  .col_1 {
    min-width: 40px;
  }

  .col_2 {
    min-width: 60px;
  }

  .col_3 {
    min-width: 100px;
  }

  .cell {
    font-size: 0.9em;
    white-space: nowrap;
    text-align: center;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    thead {
      tr,
      th {
        background-color: transparent;
      }
    }

    tbody {
      tr {
        background-color: white;
      }
    }
  }
}

.bg-transparent {
  background-color: transparent !important;
}
.selected::before {
  border: none !important;
}
</style>
